<template>
  <div class="vap-page">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>All Email Templates</h1>
      <div class="btns-container">
        <VasionButton
          id="new-template-button"
          class="last-btn"
          classProp="primary"
          @vasionButtonClicked="editEmailTemplate(0, false)"
        >
          New Email Template
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div class="main-table-container vasion-html-table-default">
        <VasionTable
          :headerColumns="ColumnValues"
          :hideColumns="hiddenColumns"
          :tableRows="tableData.Rows.Values"
          :filterByColumnDefault="1"
          :ellipsisButtonConfig="ellipsisButtonConfig"
          @ellipsis-button-clicked="ellipsisButtonClicked"
          @vasion-selection="rowClickHandler"
        />
      </div>
    </div>
    <VasionSnackbar
      id="workflow-started-snack"
      :showSnackbarBool="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
    <md-dialog id="confirmFormDelete" :md-active.sync="showDeleteDialog" :md-click-outside-to-close="false">
      <VasionConfirmationDialog :message="deletePromptMessage" @noButtonClick="toggleDeleteDialog()" @yesButtonClick="deleteEmailTemplate()" />
    </md-dialog>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

import VasionSnackbar from '@/components/shared/VasionSnackbar.vue';

export default {
  name: 'AllEmailTemplates',
  components: {
    Loading,
    VasionSnackbar,
  },
  data: function () {
    return {
      activeIdToDelete: '',
      ColumnValues: [
        'templateID',
        '_VasionIcon_',
        'Template Name',
        '_VasionEllipsisButton_',
      ],
      deletePromptMessage: '',
      hiddenColumns: ['templateID'],
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      showDeleteDialog: false,
      showSnackbarBool: false,
      snackbarImage: '',
      snackbarSubTitle: '',
      snackbarTitle: '',
      tableData: {
        Rows: {
          Values: [],
        },
      },
    }
  },
  computed: {
    ellipsisButtonConfig() { return this.$store.state.security.ellipsisButtonConfig },
    templateList() { return this.$store.state.common.emailTemplates },
  },
  async created() {
    await Promise.all([
      this.$store.dispatch('common/getEmailTemplates'),
      this.$store.dispatch('security/setEllipsisButtonConfig', 'email-templates'),
    ])
    await this.refreshTable()
  },
  methods: {
    async deleteEmailTemplate() {
      this.isLoading = true
      this.showDeleteDialog = false

      const res = await this.$store.dispatch('common/deleteEmailTemplate', this.activeIdToDelete)
      this.activeIdToDelete = ''

      if (!res) {
        this.showSnackbar(false)
      } else {
        this.showSnackbar(true)
        await this.refreshTable();
      }

      this.isLoading = false
    },
    editEmailTemplate(templateID, copy) { this.$router.push({ name: 'ConfigureEmailTemplate', params: { templateId: templateID, copy: copy } }) },
    ellipsisButtonClicked(payload) {
      if (payload?.item?.Values[0]) {
        const [id, , name] = payload.item.Values
        switch (payload.buttonId) {
          case 'edit-email-templates-button':
            this.editEmailTemplate(id, false)
            break;
          case 'delete-email-templates-button':
            this.deletePromptMessage = `Are you sure you want to delete "${name}"?`
            this.activeIdToDelete = id
            this.showDeleteDialog = true
            break;
          case 'copy-email-templates-button':
            this.editEmailTemplate(id, true)
            break;
          default:
            break;
        }
      }
    },
    async refreshTable() {
      this.isLoading = true

      await this.$store.dispatch('common/getEmailTemplates')

      this.tableData = {
        Rows: {
          Values: [],
        },
      }

      this.tableData.Rows.Values = this.templateList.map((templateElement) => {
        const dataRow = [
          templateElement.iID,
          'VasionEmailIcon',
          templateElement.sName,
          'VasionEllipsisIcon',
        ]

        const retElement = {
          Values: dataRow,
          ValueType: 'String',
        }

        return retElement
      })

      this.isLoading = false
    },
    rowClickHandler(data) {
      if (!data || !data.Values || data.Values.length <= 0) return
      this.editEmailTemplate(data.Values[0], false)
    },
    showSnackbar(worked) {
      this.snackbarTitle = worked ? 'Success!' : 'Unable to delete Email Template.'
      this.snackbarSubTitle = worked ? 'Email Template successfully deleted.' : 'The Email Template is in use and cannot be deleted.'
      this.snackbarImage = worked
      this.showSnackbarBool = true
      setTimeout(() => { this.showSnackbarBool = false }, 5000)
    },
    toggleDeleteDialog(id, name) {
      this.deletePromptMessage = `Are you sure you want to delete ${name}?`
      this.activeIdToDelete = id
      this.showDeleteDialog = !this.showDeleteDialog
    },
  },
}
</script>

<style lang="scss" scoped>
.grid-div {
  height: calc(100vh - 224px);
  overflow: auto;
}
</style>
